import { useIataStore } from "@/stores/iata";
const iata: any = useIataStore();

export interface Attribut {
  category: string;
  createdAt: string;
  id: number;
  id_sales_point: number;
  type: string;
  updatedAt: string;
  value: string;
}

export async function getAttributs(): Promise<Attribut[]> {
  const idPointDeVente =
    iata?.getIata?.[0]?.type === "PDV" ? iata.getIata[0].id : null;

  if (!idPointDeVente) return [];

  try {
    const { data } = await axios.get(
      `/dashboard/api/attribut/read/salesPoint/${idPointDeVente}`
    );

    return data?.values ?? [];
  } catch (error) {
    console.error(
      "Erreur lors du chargement des attributs du point de vente :",
      error
    );
    return [];
  }
}
